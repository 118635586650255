/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var siteLogo = $('.site-logo'),
		hamburger = $('.hamburger'),
		headerHeight = ( siteLogo.outerHeight() > hamburger.outerHeight() ) ? siteLogo.outerHeight() : hamburger.outerHeight(),
		menuButton = $('.menu-button');
	
	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerHeight
				}, 1000);
			}
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();

		// Adjust margin for inner page or page without header images
		
		if ( $('body').hasClass('no-header-images') ) {
			$('#content').css( 'margin-top', headerHeight );
		}

		if ( $('body').hasClass('inner-header') ) {
			$('#content').css( 'margin-top', headerHeight + 30 );
		}

		// Intro Animations

		$('#page').addClass( 'init' );

		setTimeout(function() {
			$('.logo-name').removeClass( 'hidden' );
			$('.logo-payoff').removeClass( 'hidden' );
		}, 1500);

		setTimeout(function() {
			$('.logo-pictogram').removeClass( 'hidden' );
		}, 2500);

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* GSAP Animations - Begin */

		// Backgrounds
		var sections = gsap.utils.toArray('.section');

		if ( sections.length > 0 ) {
			sections.forEach((section) => {
				if ( section.getAttribute('data-bgcolor' ) !== null) {
					var bgcolor = section.getAttribute('data-bgcolor');

					gsap.to(
						'#content',
						{
							backgroundColor: bgcolor,
							immediateRender: false,
							scrollTrigger: {
								trigger: section,
								start: 'top bottom',
								end: '+=100%',
								scrub: true,
								toggleActions: 'restart pause reverse pause'
							}
						}
					);
				}
			});
		}

		// Menu
		var menu = gsap.timeline({
			paused: true,
			onStart: function() {
				$('body').addClass( 'open-menu' );
			},
			onReverseComplete: function() {
				$('body').removeClass( 'open-menu' );
				tween.reverse();
			}
		});

		menu.to(
			'.menu-blend',
			{
				duration: 0.5,
				x: 0,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			}
		).to(
			'#menu-offcanvas',
			{
				duration: 1,
				x: 0,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			}
		).to(
			'.menu-item',
			{
				duration: 0.5,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			},
			'-=0.1'
		).to(
			'.menu-bottom',
			{
				opacity: 1,
				ease: 'Power2.inOut'
			}
		);

		// Sub Menus
		var tween = gsap.from(
			'.sub-menu',
			{
				duration: 0.25,
				height: 0,
				paused: true,
				reversed: true,
				ease: 'Power2.inOut'
			}
		);

		// Single letter animation
		function createTextAnimations() {
			$('.letter-animation').each(function(index) {
				let triggerElement = $(this);
				let targetElement = $(this).find('span');
			
				let tl = gsap.timeline({
					scrollTrigger: {
						trigger: triggerElement,
						start: 'top bottom',
						end: 'bottom top',
						toggleActions: 'restart pause resume reverse'
					}
				});

				tl.from(targetElement, {
					duration: 0.6,
					ease: 'Circ.easeOut',
					yPercent: 80,
					stagger: {
						amount: 0.7,
						from: '0'
					}
				});
			});
		}

		createTextAnimations();

		// Titles (sliding up)
		var titles = gsap.utils.toArray('.slide');

		if ( titles.length > 0 ) {
			titles.forEach((title) => {
				var delay = title.getAttribute('data-delay'),
					scroll = title.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				title.parentNode.style.overflow = 'hidden';

				gsap.from(
					title,
					{
						delay: delay,
						duration: 1.5,
						yPercent: 120,
						scrollTrigger: {
							trigger: title,
							start: start
						}
					}
				);
			});
		}

		// Texts (fading up)
		var texts = gsap.utils.toArray('.fade');

		if ( texts.length > 0 ) {
			texts.forEach((text) => {
				var delay = text.getAttribute('data-delay'),
					scroll = text.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				gsap.from(
					text,
					{
						delay: delay,
						duration: 1,
						autoAlpha: 0,
						y: 50,
						scrollTrigger: {
							trigger: text,
							start: start,
							toggleActions: 'restart pause resume reverse'
						}
					}
				);
			});
		}

		// Extra words (discover)
		var extra = $('#extra');

		if ( extra.length > 0 ) {
			var items = gsap.utils.toArray('.split span');

			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: '.split',
					toggleActions: 'restart pause reverse pause'
				}
			});

			items.forEach((item) => {
				tl.to(
					item,
					{
						duration: 0.4,
						ease: 'none',
						opacity: 1
					}
				);
			});
		}

		/* GSAP Animations - End */

		// Activate/Deactivate sub-menus

		$('#primary-menu').find('a[href="#"]').each(function() {
			$(this).on('click', function(e) {
				e.preventDefault();

				tween.reversed() ? tween.play() : tween.reverse();
			});
		});

		// Hide/show menu off canvas

		hamburger.on('click', function(e) {
			e.preventDefault();
			
			menuButton.toggleClass('collapsed');

			if ( ! menuButton.hasClass('collapsed') ) {
				$('#primary-menu .menu-item > a').next('.sub-menu').removeClass('open');
				menu.play();
			} else {
				menu.reverse();
			}
		});

		// Equal Heights
		if ( $(window).width() >= 1024 ) {
			$('.equalHeight').equalHeights();
		}

	});

	// Code to run when the browser window has been resized

	var timer_id;

	$( window ).on( 'resize', function() {

		clearTimeout(timer_id);
		timer_id = setTimeout(function() {

			if ( $(window).width() >= 1024 ) {
				// Adjust margin for inner page or page without header images

				if ( $('body').hasClass('no-header-images') ) {
					$('#content').css( 'margin-top', headerHeight );
				}

				if ( $('body').hasClass('inner-header') ) {
					$('#content').css( 'margin-top', headerHeight + 30 );
				}

				// Equal Heights
				
				$('.equalHeight').equalHeights();
			}
			
		}, 300);

	});

})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

// Menu rotation

if ( document.documentElement.clientWidth > 1024 ) {
	const menuLabel = document.getElementById( 'menu-label' );
	window.onscroll = function() {
		menuLabel.style.transform = 'rotate(' + (window.pageYOffset / 8) + 'deg)';
	}
}

/* Swiper - Begin */

// Reviews slideshow

const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
	loop: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	allowTouchMove: false,
	speed: 10000,
	autoplay: {
		delay: 1,
		disableOnInteraction: false,
	},
});

// Social slideshow

const socialSlider = new Swiper('#social .social-slideshow', {
	loop: true,
	slidesPerView: 1,
	speed: 1000,
	allowTouchMove: false,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			slidesPerGroup: 3,
			speed: 2000
		}
	},
});

/* Swiper - End */
